import { Image as ImageType, ViewPresentation } from '@yleisradio/areena-types';
import classNames from 'classnames';
import Image from 'next/image';
import React, { useEffect, useRef, useState } from 'react';
import { loaderUrl, backgroundImageLoader } from 'utils/cloudinary';
import styles from './Background.module.scss';

const SIZES = '(min-width: 480px) 100vw, 152vw'; // (16 / 9) / (375 / 320) ≈ 152%
const TRANSITION_WAIT_TIME = 450;

interface Props {
  image: ImageType | undefined;
  isBlurred: boolean;
  viewPresentation: ViewPresentation | undefined;
}

const Background: React.FC<Props> = ({
  image,
  isBlurred,
  viewPresentation,
}) => {
  const isForPackageView = viewPresentation === 'package-view';

  const latestSrc = image ? loaderUrl(image) : null;
  const [srcA, setSrcA] = useState<string | null>(latestSrc);
  const [srcB, setSrcB] = useState<string | null>(null);
  const [isSrcBLoaded, setIsSrcBLoaded] = useState(false);

  const backgroundStartRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const backgroundStartElement = backgroundStartRef.current;
    const wrapperElement = wrapperRef.current;

    if (backgroundStartElement && wrapperElement && latestSrc) {
      const listener = () => {
        const contentStartOffset = backgroundStartElement.offsetTop;
        const contentScroll = window.scrollY - contentStartOffset;
        const scrollDistance = (window.innerHeight - contentStartOffset) / 2;

        const progress = Math.min(
          Math.max(contentScroll / scrollDistance, 0),
          1
        );
        wrapperElement.style.opacity = (1 - progress).toString();
      };

      listener();
      window.addEventListener('resize', listener);
      window.document.addEventListener('scroll', listener);

      return () => {
        window.removeEventListener('resize', listener);
        window.document.removeEventListener('scroll', listener);
      };
    }
  }, [latestSrc]);

  useEffect(() => {
    if (!latestSrc) {
      setSrcA(null);
      setSrcB(null);
      setIsSrcBLoaded(false);
    } else if (latestSrc !== srcA) {
      setSrcB(latestSrc);
      setIsSrcBLoaded(false);
    }
  }, [latestSrc, srcA]);

  if (!isForPackageView && !latestSrc) {
    return null;
  }

  return (
    <>
      <div className="Background-start" ref={backgroundStartRef} />
      <div className={styles.stickyWrapper} ref={wrapperRef}>
        <div
          className={classNames(
            styles.background,
            isBlurred && styles.backgroundBlurred,
            !latestSrc && styles.backgroundWithoutImage,
            isForPackageView && styles.backgroundInPackageView
          )}
        >
          {srcA && (
            <Image
              key={srcA}
              className={classNames(styles.image, !!srcB && styles.imageHidden)}
              src={srcA}
              fill
              alt=""
              role="presentation"
              priority
              sizes={SIZES}
              loader={backgroundImageLoader({ aspectRatio: '16:9', isBlurred })}
            />
          )}
          {srcB && (
            <Image
              key={srcB}
              className={classNames(
                styles.image,
                !isSrcBLoaded && styles.imageHidden
              )}
              src={srcB}
              fill
              alt=""
              role="presentation"
              priority
              sizes={SIZES}
              loader={backgroundImageLoader({ aspectRatio: '16:9', isBlurred })}
              onLoad={() => {
                setIsSrcBLoaded(true);
                setTimeout(() => {
                  setSrcA(srcB);
                  setSrcB(null);
                  setIsSrcBLoaded(false);
                }, TRANSITION_WAIT_TIME);
              }}
              onError={() => {
                setSrcA(srcB);
                setSrcB(null);
                setIsSrcBLoaded(false);
              }}
            />
          )}
          <div className={styles.gradient1}></div>
          <div className={styles.gradient2}></div>
        </div>
      </div>
    </>
  );
};

export default Background;
