import {
  Language,
  Service,
  View as ViewData,
  ViewMeta,
} from '@yleisradio/areena-types';
import { HeaderImageAspectRatio } from 'components/Header/types';
import { ViewMetadata } from 'components/Metadata';
import { View } from 'components/View';
import { AnalyticsContextProvider } from 'contexts/AnalyticsContext';
import { useAreenaService } from 'contexts/AreenaServiceContext';
import { ReferrerProvider } from 'contexts/ReferrerContext';
import apm from 'elastic-apm-node';
import { useFavoriteUpdate } from 'hooks/useFavoriteUpdate';
import { useIsomorphicLayoutEffect } from 'hooks/useIsomorphicLayoutEffect';
import { GetServerSideProps } from 'next';
import { useRouter } from 'next/router';
import { FunctionComponent, useMemo } from 'react';
import { NotFoundError } from 'services/areena-api/fetchErrors';
import { fetchSlugs, getView } from 'services/areena-api/fetchers';
import { clientPathToViewPath } from 'services/areena-api/path';
import logger from 'services/logger';
import { getSchemaOrgNodes } from 'services/programs-api/fetchers';
import { SchemaOrgNode } from 'services/schema-org/types';
import { filterViewByAudience } from 'utils/content';
import { getItemService, isRadioItem } from 'utils/item';
import { isAuthenticated } from 'utils/login';
import {
  getItemRedirect,
  getLegacyLivePageRedirect,
  getPersonalPageRedirect,
} from 'utils/redirects';
import {
  getItemIdFromClientPath,
  isPackageViewPathWithSlug,
  serviceFromClientPath,
} from 'utils/url';

type Props = {
  headerImageAspectRatio: HeaderImageAspectRatio;
  meta: ViewMeta;
  path: string;
  userIsAuthenticated: boolean;
  view: ViewData;
  selectedTab: string | null;
  metaImageAspectRatio: '16:9' | '1:1';
  referrer: string | null;
  schemaOrgNodes: SchemaOrgNode[];
  slugs: Record<string, string> | null;
  areenaService: Service | null;
};

const Page: FunctionComponent<Props> = ({
  headerImageAspectRatio,
  meta,
  path,
  userIsAuthenticated,
  view,
  selectedTab,
  metaImageAspectRatio,
  referrer,
  schemaOrgNodes,
  slugs,
  areenaService,
}) => {
  const { query } = useRouter();

  const filteredViewData = useMemo(
    () => filterViewByAudience(view, userIsAuthenticated),
    [view, userIsAuthenticated]
  );

  const { setAreenaService } = useAreenaService();

  useIsomorphicLayoutEffect(() => {
    setAreenaService(areenaService ?? undefined);
  }, [areenaService, setAreenaService]);

  useFavoriteUpdate({ alternativeIds: meta.isViewOf, item: meta.item });

  return (
    <>
      <ViewMetadata
        path={path}
        query={query}
        schemaOrgNodes={schemaOrgNodes}
        view={view}
        viewMeta={meta}
        metaImageAspectRatio={metaImageAspectRatio}
        slugs={slugs}
      />
      <ReferrerProvider referrer={referrer || undefined}>
        <AnalyticsContextProvider context={view.analytics?.context?.comscore}>
          <View
            headerImageAspectRatio={headerImageAspectRatio}
            view={filteredViewData}
            viewKey={path}
            selectedTab={selectedTab}
          />
        </AnalyticsContextProvider>
      </ReferrerProvider>
    </>
  );
};

export default Page;

export const getServerSideProps: GetServerSideProps<Props> = async ({
  locale,
  req,
  res,
  resolvedUrl,
}) => {
  apm.setTransactionName('GET [...path]');

  const {
    cookies,
    headers: { cookie: cookieHeader, referer: referrer = null },
  } = req;

  const {
    pathname: path,
    search: query,
    searchParams: queryParams,
  } = new URL(resolvedUrl, 'https://localhost/');
  const language: Language = locale === 'sv' ? 'sv' : 'fi';

  const personalPageRedirect = getPersonalPageRedirect(path, language);

  if (personalPageRedirect) {
    return { redirect: { destination: personalPageRedirect, permanent: true } };
  }

  const legacyLivePageRedirect = getLegacyLivePageRedirect(path, language);

  if (legacyLivePageRedirect) {
    res.setHeader('Cache-Control', 'max-age=60');
    return {
      redirect: { destination: legacyLivePageRedirect, permanent: true },
    };
  }

  const service = serviceFromClientPath(path);

  const slugs = isPackageViewPathWithSlug(path)
    ? await fetchSlugs(service, language).then((res) => res?.data)
    : undefined;

  const apiPath = clientPathToViewPath(path, {
    slugs,
  });

  const itemId = getItemIdFromClientPath(path);

  if (apiPath) {
    const [settledViewResponse, settledSchemaOrgResponse] =
      await Promise.allSettled([
        getView({
          path: apiPath,
          language,
          requestCookie: cookieHeader,
        }),
        itemId ? getSchemaOrgNodes(itemId, language) : Promise.resolve([]),
      ]);

    const schemaOrgNodes =
      settledSchemaOrgResponse.status === 'fulfilled'
        ? settledSchemaOrgResponse.value
        : [];

    if (settledSchemaOrgResponse.status === 'rejected') {
      logger.error(settledSchemaOrgResponse.reason);
    }

    if (settledViewResponse.status === 'fulfilled') {
      const { cacheControl, setCookieHeaders, meta, view } =
        settledViewResponse.value;
      const { item } = meta;

      if (cacheControl) {
        res.setHeader('Cache-Control', cacheControl);
      }
      if (setCookieHeaders) {
        res.setHeader('Set-Cookie', setCookieHeaders);
      }

      const pageRedirect = getItemRedirect(
        path,
        language,
        meta,
        view.presentation
      );

      if (pageRedirect) {
        return redirectWithQuery(pageRedirect, query);
      }

      res.setHeader('Vary', 'Cookie');

      const isPodcast = item && isRadioItem(item);
      const headerImageAspectRatio = isPodcast ? '1:1' : '16:9';
      const metaImageAspectRatio = isPodcast ? '1:1' : '16:9';

      const selectedTab = queryParams.get('t');

      const areenaService =
        getAreenaService(path) ?? (item && getItemService(item));

      return {
        props: {
          view,
          meta,
          path,
          userIsAuthenticated: isAuthenticated(cookies),
          headerImageAspectRatio,
          selectedTab,
          metaImageAspectRatio,
          referrer,
          schemaOrgNodes,
          slugs: slugs ?? null,
          areenaService: areenaService ?? null,
        } satisfies Props,
      };
    } else {
      const error: unknown = settledViewResponse.reason;

      if (error instanceof NotFoundError) {
        return { notFound: true };
      }
      throw error;
    }
  }

  return { notFound: true };
};

function redirectWithQuery(path: string, query: string) {
  return { redirect: { destination: `${path}${query}`, permanent: true } };
}

function getAreenaService(path: string): Service | null {
  if (path.startsWith('/tv')) return 'tv';

  if (path.startsWith('/podcastit') || path.startsWith('/poddar'))
    return 'radio';

  return null;
}
